@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Fasthand&family=Roboto+Condensed&display=swap");
body {
  font-family: "Roboto Condensed", sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(242,140,24);
  border-radius: 10px;
}